import { productOnProduct } from "../fragments/product";

export const getProductByEntityId = (entityId: number) => {
	return {
		variables: {
			entityId,
		},
		query: /* GraphQL */ `
			query getProductByEntityId($entityId: Int!) {
				site {
					product(entityId: $entityId) {
						...product
					}
				}
			}
			${productOnProduct}
		`,
	};
};

export const getProductsByEntityIds = (entityIds: number[]) => {
	return {
		variables: {
			entityIds,
		},
		query: /* GraphQL */ `
			query getProductsByEntityIds($entityIds: [Int!]!) {
				site {
					products(entityIds: $entityIds) {
						edges {
							node {
								...product
							}
						}
					}
				}
			}
			${productOnProduct}
		`,
	};
};

export const getProductBySku = (sku: string) => {
	return {
		variables: {
			sku,
		},
		query: /* GraphQL */ `
			query getProductBySku($sku: String!) {
				site {
					product(sku: $sku) {
						...product
					}
				}
			}
			${productOnProduct}
		`,
	};
};

export const getQuickSearchResultsQuery = (searchTerm: string) => {
	return {
		variables: {
			filters: { searchTerm },
		},
		query: /* GraphQL */ `
			query getQuickSearchResults($filters: SearchProductsFiltersInput!) {
				site {
					search {
						searchProducts(filters: $filters) {
							products(first: 5) {
								edges {
									node {
										...product
									}
								}
							}
						}
					}
				}
			}
			${productOnProduct}
		`,
	};
};
