// import { getStoreRegions } from "@/lib/bigcommerce/bigcommerce.config";

export const validRegions = ["global", "eu", "us", "ca"]; // TODO - this kept failing: ...getStoreRegions()

export enum Header {
	X_BUILDER_STATUS = "x-builder-status",
	X_LANG = "X-Next-Locale",
	X_STORE = "x-store",
	X_REQUEST_URL = "x-request-url",
	X_STORE_ERROR = "x-store-error",
}

export enum Cookie {
	LOCALE = "Next-Locale",
	CART_ID = "cartId",
}

export const CAROUSEL_PRODUCT_LIMIT = 16;
export const PERSISTENT_CART = "Persistent Cart";
