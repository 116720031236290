import { Price } from "./price";

export const productOnProduct = /* GraphQL */ `
	fragment product on Product {
		entityId
		sku
		path
		name
		description
		warranty
		maxPurchaseQuantity
		minPurchaseQuantity
		pricesIncTax: prices(includeTax: true) {
			${Price}
		}
		pricesExTax: prices(includeTax: false) {
			${Price}
		}
		customFields {
			edges {
				node {
					entityId
					name
					value
				}
			}
		}

		categories {
			edges {
				node {
					name
					path
					breadcrumbs(depth: 3) {
						edges {
							node {
								entityId
								name
								path
							}
						}
					}
					defaultImage {
						url(width:300)
						altText
					}
				}
			}
		}
		mpn
		images {
			edges {
				node {
					url(width: 1000)
					altText
				}
			}
		}
		brand {
			name
            path
            entityId
		}
		condition
		plainTextDescription(characterLimit: 1000)
		availabilityV2 {
			status
			... on ProductUnavailable {
				message
				status
			}
		}
		inventory {
			isInStock
			hasVariantInventory
			aggregated {
				availableToSell
				warningLevel
			}
		}
		seo {
			pageTitle
			metaDescription
			metaKeywords
		}
	}
`;
