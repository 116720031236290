import mapProduct from "../5874/mapper/ProductMapper";
import { bigcommerceGraphqlAPI, bigcommerceRestAPI } from "./client";
import { isValidJSON } from "@/utils/is-valid-json";
import {
	getProductByEntityId,
	getProductBySku,
	getProductsByEntityIds,
} from "./graphql/queries/product";
import { getProductsByEntityId } from "./graphql/queries/products";
import { ProductData } from "@/lib/5874/types";
import { CAROUSEL_PRODUCT_LIMIT } from "@/constants";

export const getProduct = async (entityId: number) => {
	if (!entityId) return null;

	const response = await bigcommerceGraphqlAPI<any>({
		query: getProductByEntityId(entityId),
		cache: "default",
	});

	if (response.status !== 200) {
		console.error(`${response.status}: Cannot return product.`);
		return;
	}

	const product = response.data.site.product;
	const warranty = isValidJSON(product.warranty);
	const { childSKUs, sisterSKUs, kitSKUs } = warranty;

	if (childSKUs) {
		const response = await bigcommerceRestAPI<ProductData[]>({
			endpoint: `/v3/catalog/products?sku:in=${childSKUs}&include_fields=id`,
		});

		if (response.status !== 200) {
			console.error(`${response.status}: Cannot return children products.`);
			return;
		}

		const ids = response?.body?.map((entry) => entry.id);
		if (!ids) return;

		const _response = await bigcommerceGraphqlAPI<any>({
			query: getProductsByEntityIds(ids),
			cache: "default",
		});

		if (_response.status !== 200) {
			console.error(`${_response.status}: Cannot return children products.`);
			return;
		}

		product.children = _response.data.site.products.edges.map((node: any) =>
			mapProduct(node),
		);
	}

	if (sisterSKUs) {
		const response = await bigcommerceRestAPI<ProductData[]>({
			endpoint: `/v3/catalog/products?sku:in=${sisterSKUs}&include_fields=id`,
		});

		if (response.status !== 200) {
			console.error(`${response.status}: Cannot return sibling products.`);
			return;
		}

		const ids = response?.body?.map((entry) => entry.id);
		if (!ids) return;

		const _response = await bigcommerceGraphqlAPI<any>({
			query: getProductsByEntityIds(ids),
			cache: "default",
		});

		if (_response.status !== 200) {
			console.error(`${_response.status}: Cannot return sibling products.`);
			return;
		}

		product.siblings = _response.data.site.products.edges.map((node: any) =>
			mapProduct(node),
		);
	}

	if (kitSKUs) {
		const _kitSKUs = formatKitSKUs(kitSKUs);
		const skus = _kitSKUs.map((kit: Record<string, any>) => kit.sku);

		const response = await bigcommerceRestAPI<ProductData[]>({
			endpoint: `/v3/catalog/products?sku:in=${skus}&include_fields=id`,
		});

		if (response.status !== 200) {
			console.error(`${response.status}: Cannot return kit products.`);
			return;
		}

		const ids = response?.body?.map((entry) => entry.id);
		if (!ids) return;

		const _response = await bigcommerceGraphqlAPI<any>({
			query: getProductsByEntityIds(ids),
			cache: "default",
		});

		if (_response.status !== 200) {
			console.error(`${_response.status}: Cannot return kit products.`);
			return;
		}

		product.kitProducts = _response.data.site.products.edges.map(
			(data: any) => {
				if (!data.node.entityId) return;

				return mapProduct({
					...data.node,
					quantity:
						data.node?.availabilityV2?.status !== "Available"
							? 0
							: _kitSKUs.find((kit) => kit.sku == data.node.sku)?.quantity,
				});
			},
		);
	}

	return mapProduct(product);
};

const formatKitSKUs = (kitSKUs: Record<string, string>[]) => {
	const array = [];

	for (let i = 0; i < kitSKUs.length; i += 2) {
		array.push({
			sku: kitSKUs[i],
			quantity: kitSKUs[i + 1].quantity,
		});
	}

	return array;
};

/**
 * Retrieves an array of products from BigCommerce based on an array of SKUs.
 *
 * @param {string[]} skuArray - An array of SKUs to search for.
 * @return {Promise<Product[] | null>} - A Promise that resolves to an array of Product objects if the request is successful, or null if the request fails.
 */
export const getProductsBySkus = async (skuArray: string[]) => {
	const skuParamString = skuArray.join(",");

	const res = await bigcommerceRestAPI<any>({
		endpoint: `/v3/catalog/products?include_fields='&sku:in=${skuParamString}&include=images,videos&include_fields=images,videos,layout_file`,
	});

	if (res.status !== 200) {
		console.error(`${res.status}: Cannot return product.`);
		return null;
	}

	const ids = res.body.map((product: { id: string }) => product.id);

	const products = await getProductsByIds(ids, res);

	return products;
};

/**
 * Retrieves products by their entity IDs.
 *
 * NOTE: Very slow, returns data after fetching all products, images and all children + images
 * @param {number[]} entityIds - An array of entity IDs.
 * @param {any} restResponse - (optional) The REST response. Defaults to null.
 * @return {Promise<any[]>} - A promise that resolves to an array of products.
 */
export const getProductsByIds = async (
	entityIds: number[],
	restResponse: any = null,
) => {
	if (!entityIds || !entityIds.length) return null;

	const promises = [];

	try {
		const res = bigcommerceGraphqlAPI<any>({
			query: getProductsByEntityId(entityIds),
			cache: "default",
		});

		promises.push(res);

		if (!restResponse) {
			const rest = bigcommerceRestAPI<any>({
				endpoint: `/v3/catalog/products/?id:in=${entityIds.join(
					",",
				)}&include=images,videos&include_fields=images,videos,layout_file`,
			});

			promises.push(rest);
		}

		const [products, media = restResponse] = await Promise.all(promises);

		//@ts-ignore the data part of this is flagging and I cant see why
		const correctedProducts = products.data.site.products.edges.map(
			({ node }: any) => {
				const correspondingMediaData = media.body.find(
					(mediaObject: any) => mediaObject.id === node.entityId,
				);

				return mapProduct({
					...node,
					...correspondingMediaData,
				});
			},
		);

		const childrenDataPromises = correctedProducts.map(async (parent: any) => {
			try {
				const warranty = isValidJSON(parent.warranty);
				const childSkus = warranty.childSKUs;

				if (childSkus && childSkus.length > 0) {
					const childSkuPromises = childSkus.map(async (childProd: any) => {
						const details = await bigcommerceGraphqlAPI<any>({
							query: getProductBySku(childProd),
							cache: "default",
						});

						return mapProduct({
							...details.data.site.product,
						});
					});

					const childData = await Promise.all(childSkuPromises);

					return { ...parent, children: childData.filter(Boolean) };
				}

				return parent;
			} catch (error) {
				console.error(`Error processing parent with id ${parent.id}:`, error);
				return parent;
			}
		});

		const detailedProducts = await Promise.all(childrenDataPromises);

		return detailedProducts;
	} catch (error) {
		console.error("🚀 ~ getProductsById ~ error:", error);
	}
};

//
export const getProductsWithoutChildrenByIds = async (entityIds: number[]) => {
	if (!entityIds || !entityIds.length) return null;

	try {
		const response = await bigcommerceGraphqlAPI<any>({
			query: getProductsByEntityId(entityIds),
			cache: "default",
		});

		return response.data.site.products.edges.map((entry: any) =>
			mapProduct(entry),
		);
	} catch (error) {
		console.error("🚀 ~ getProductsById ~ error:", error);
	}
};

export const getProductsWithChildrenByIds = async (entityIds: number[]) => {
	if (!entityIds || !entityIds.length) return null;

	try {
		const response = await bigcommerceGraphqlAPI<any>({
			query: getProductsByEntityId(entityIds),
			cache: "no-store",
		});

		const products = response.data.site.products.edges.map((entry: any) =>
			mapProduct(entry),
		);

		const promises = products.map(async (product: ProductData) => {
			const warranty = isValidJSON(product.warranty);
			const { childSKUs } = warranty;

			if (childSKUs) {
				const response = await bigcommerceRestAPI<ProductData[]>({
					endpoint: `/v3/catalog/products?sku:in=${childSKUs}&include_fields=id&=${Date.now()}`,
				});

				if (response.status !== 200) {
					console.error(`${response.status}: Cannot return children products.`);
					return;
				}

				const ids = response?.body?.map((entry) => entry.id);
				if (!ids) return;

				const _response = await bigcommerceGraphqlAPI<any>({
					query: getProductsByEntityIds(ids),
					cache: "no-store",
				});

				if (_response.status !== 200) {
					console.error(
						`${_response.status}: Cannot return children products.`,
					);
					return;
				}

				return {
					product,
					children: _response.data.site.products.edges.map((node: any) =>
						mapProduct(node),
					),
				};
			}

			return product;
		});

		return await Promise.all(promises);
	} catch (error) {
		console.error("🚀 ~ getProductsById ~ error:", error);
	}
};

// Step 1: Fetch product data
export const fetchProductDataByIds = (entityIds: number[]) => {
	const idsToRequest = entityIds.slice(0, CAROUSEL_PRODUCT_LIMIT);
	return bigcommerceGraphqlAPI<any>({
		query: getProductsByEntityId(idsToRequest),
		cache: "default",
	});
};

// Step 2: Fetch media data if not already available
export const fetchMediaData = (entityIds: number[]) => {
	return bigcommerceRestAPI<any>({
		endpoint: `/v3/catalog/products/?id:in=${entityIds.join(
			",",
		)}&include=images,videos&include_fields=images,videos,layout_file`,
	});
};

// Step 3: Combine product and media data
export const mapProductDataWithMedia = (products: any, media: any) => {
	return products.data.site.products.edges.map(({ node }: any) => {
		const correspondingMediaData = media.body.find(
			(mediaObject: any) => mediaObject.id === node.entityId,
		);
		return mapProduct({
			...node,
			...correspondingMediaData,
		});
	});
};

// Step 4: Fetch child products if present
export const fetchChildProducts = async (prods: ProductData[]) => {
	const childrenDataPromises = prods.map(async (parent: any) => {
		try {
			const warranty = isValidJSON(parent.warranty);
			const childSkus = warranty.childSKUs;

			if (childSkus && childSkus.length > 0) {
				const childSkuPromises = childSkus.map(async (childProd: any) => {
					const details = await bigcommerceGraphqlAPI<any>({
						query: getProductBySku(childProd),
						cache: "default",
					});

					return mapProduct({
						...details.data.site.product,
					});
				});

				const childData = await Promise.all(childSkuPromises);

				return { ...parent, children: childData };
			}

			return parent;
		} catch (error) {
			console.error(`Error processing parent with id ${parent.id}:`, error);
			return parent;
		}
	});
	const detailedProducts = await Promise.all(childrenDataPromises);
	return detailedProducts;
};
